<template>
  <v-container>
    <span class="title_set">정보설정</span>

    <v-layout mb-2 justify-start row>
      <v-card outlined class="elevation-5 ma-5" max-width="200" max-height="200"
          :disabled="this.$getters.userInfo.grade > 'UT05'" 
          @click="$router.push('/farminfo/registerinfo/company')"
          >
          <v-card-text>
            <v-layout align-center mb-0 row>
              <p class="text-h6 text--primary ml-2"> 공간등록  </p>
              <v-spacer></v-spacer>
              <v-icon color=#3a4f3f size="65">mdi-city</v-icon>
            </v-layout>
              경영체, 사업장(농장), 건물(축사), 구역(돈방) 을 등록 / 확인 할 수 있습니다.
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn text color="teal darken-1" 
              :disabled="this.$getters.userInfo.grade > 'UT05'"
              @click="$router.push('/farminfo/registerinfo/company')"
              >
              확인
            </v-btn>
          </v-card-actions>
      </v-card>

      <v-card outlined class="elevation-5 ma-5" max-width="200" max-height="200" 
          @click="$router.push('/device/registerdevice')"
          >
          <v-card-text>
            <v-layout align-center mb-0 row>
              <p class="text-h6 text--primary ml-2"> 장치등록 </p>
              <v-spacer></v-spacer>
              <v-icon color=#3a4f3f size="65">mdi-usb</v-icon>
            </v-layout>
              멀티게이트, 각종컨트롤러, 센서 등 장치를 등록 / 확인할 수 있습니다.
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn text color="teal darken-1" 
              :disabled="this.$getters.userInfo.grade > 'UT05'"
              @click="$router.push('/device/registerdevice')"
              >
              확인
            </v-btn>
          </v-card-actions>
      </v-card>

      <v-card outlined class="elevation-5 ma-5" max-width="200" max-height="200">
          <v-card-text>
            <v-layout align-center mb-0 row>
              <p class="text-h6 text--primary ml-2"> 알림설정 </p>
              <v-spacer></v-spacer>
              <v-icon color=#3a4f3f size="62">mdi-bell-ring</v-icon>
            </v-layout>
              피그로 APP 알림에서 사용될 기준 돈방 및 온도 설정을 할 수 있습니다.
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn text color="teal darken-1" 
            :disabled="this.$getters.userInfo.grade > 'UT05'"
            @click="$router.push('/alert/AlertSet2')"
            >
              알림설정
            </v-btn>
            <v-btn text color="blue-grey" 
            @click="$router.push('/alert/AlertPersonalSet')"
            >
              수신설정
          </v-btn>
          </v-card-actions>
      </v-card>
    </v-layout>
  
    <span class="title_set">사용자/기초정보</span>
    <v-layout mb-2 justify-start row>
      <v-card outlined class="elevation-5 ma-5" max-width="200" max-height="200" 
        :disabled="this.$getters.userInfo.grade > 'UT03'"
        @click="$router.push('/user/registeruser')"
        >
        <v-card-text>
          <v-layout align-center mb-0 row>
            <p class="text-h5 text--primary ml-2"> 사용자 </p>
            <v-spacer></v-spacer>
            <v-icon color=#3a4f3f size="65">mdi-account-multiple</v-icon>
          </v-layout>
            사용자 정보를 <br> 등록 / 조회 / 수정 할 수있습니다.
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text color="teal darken-1" 
            :disabled="this.$getters.userInfo.grade > 'UT03'"
            @click="$router.push('/user/registeruser')"
            >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card outlined class="elevation-5 ma-5" max-width="200" max-height="200"  
        :disabled="this.$getters.userInfo.grade > 'UT05'"
        @click="$router.push('/farminfo/listdealcompanies')"
        >
        <v-card-text>
          <v-layout align-center mb-0 row>
            <p class="text-h5 text--primary ml-2"> 거래처 </p>
            <v-spacer></v-spacer>
            <v-icon color=#3a4f3f size="65">mdi-truck</v-icon>
          </v-layout>
            입식처 /출하처 / 도축장 등의 거래처 정보를 등록 / 조회 할 수 있습니다.
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text color="teal darken-1" 
            :disabled="this.$getters.userInfo.grade > 'UT05'"
            @click="$router.push('/farminfo/dealcompany')"
            >
            등록
          </v-btn>
          <v-btn text color="blue-grey" 
            :disabled="this.$getters.userInfo.grade > 'UT05'"
            @click="$router.push('/farminfo/listdealcompanies')"
            >
            목록
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card outlined class="elevation-5 ma-5" max-width="200" max-height="200"  
        @click="$router.push('/setup/basedata')"
        >
        <v-card-text>
          <v-layout align-center mb-0 row>
            <p class="text-h6 text--primary ml-2"> 기초DATA </p>
            <v-spacer></v-spacer>
            <v-icon color=#3a4f3f size="65">mdi-calculator</v-icon>
          </v-layout>
            일일증체량,적정온도 등 <br> 자료산출의 가이드 데이터를 확인할 수 있습니다.
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text color="teal darken-1" 
            :disabled="this.$getters.userInfo.grade > 'UT05'"
            @click="$router.push('/setup/basedata')"
            >
            확인
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>

    <span class="title_set">장치정보</span>
    <v-layout mb-2 justify-start row>
      <v-card outlined class="elevation-5 ma-5" max-width="200" max-height="200"  >
        <v-card-text>
          <v-layout align-center mb-0 row>
            <p class="text-h6 text--primary ml-2"> 센서상태 </p>
            <v-spacer></v-spacer>
            <v-icon color=#3a4f3f size="65">mdi-access-point</v-icon>
          </v-layout>
            센서 수신 목록과 <br> 센서의 수신 DATA 를 확인 할 수있습니다.
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text color="teal darken-1" 
            @click="$router.push('/device/listsensoronoff')"
            >
            수신목록
          </v-btn>
          <v-btn text color="blue-grey" 
            @click="$router.push('/device/monitorsensor')"
            >
            센서DATA
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- <v-card outlined class="elevation-5 ma-5" max-width="190" max-height="190" 
        @click="$router.push('/device/settingcontrol')"
        >
        <v-card-text>
          <v-layout align-center mb-0 row>
            <p class="text-h6 text--primary ml-2"> 제어설정 </p>
            <v-spacer></v-spacer>
            <v-icon color=#3a4f3f size="65">mdi-fan</v-icon>
          </v-layout>
            팬 컨트트롤러의 <br> 현재 설정을 확인할 수 있습니다.
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn text color="teal darken-1" 
            :disabled="this.$getters.userInfo.grade > 'UT05'"
            @click="$router.push('/device/settingcontrol')"
            >
            확인
          </v-btn>
        </v-card-actions>
      </v-card> -->


    </v-layout>
 </v-container>
</template>

<script>

  export default {
    name: 'MainMenu',

    methods: {
      control() {
        // console.log("goto control")
        this.$router.push("/site")                        
      },

    },
  }
</script>
<style lang="scss" scoped>
.container {
  max-width: 960px;
}
.v-btn {
  // display: flex;
  // color:rgb(68, 65, 65) !important;
  font-size:11pt  !important;
  font-weight: bold !important;
  // width: 100 !important;
  // height: 100 !important;
  // align-items: center !important;
  // border: none !important;
  // background-color: white !important;
  // cursor: pointer;
  margin-TOP: -10px !important;
  // padding: 0px !important;
}
.title_set {
  color: black;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  margin-left: 25px;
  letter-spacing: -0.6px;
}

// .v-card {
//   border-radius: 5px !important;
//   border: solid 2px #3a4f3f !important;
//   background-color: white !important;
// }

</style>
